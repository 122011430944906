import React from "react";
import RecurringCallbackManager from "../../common/RecurringCallbackManager";
import { reduxConnectedComponent } from "../../util/reduxConnectedHOC";
import { getActiveLimboList } from "../../redux/recipients/thunks";

// run every 45 seconds
const FETCH_INTERVAL = 0.75;

/**
 * @callback ReleaseInactiveClbk
 * @returns {Promise} The request promise
 */

/**
 * @typedef Props
 * @type {object}
 * @property {ReleaseInactiveClbk} getActiveLimboList Function to release inactive recipients
 */

/**
 * Component to fetch active limbo list every minute
 * @param {Props} props
 * @returns jsx
 */
const FetchLimboListInterval = ({ getActiveLimboList }) => {
    return (
        <RecurringCallbackManager
            intervalTime={FETCH_INTERVAL}
            intervalName="getLimboList"
            task={getActiveLimboList}
            executeTaskAtStart={true}
        />
    )
};

export default reduxConnectedComponent(
    FetchLimboListInterval,
    undefined,
    { getActiveLimboList }
);
