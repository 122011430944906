import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { StatusBarr } from "./StatusBar.component";
import { logout } from "../redux/auth/actions";
//----------------------------------------------------------------------------------------------------
// DCMTopBar

const StatusBar = (props) => <StatusBarr {...props} />

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { logout },
    dispatch
  );

const mapStateToProps = (state) => ({
  selectedCampaignName: state.campaign.name,
  campaignStatus: state.campaign.active,
  campaignid: state.auth.campaignid,
  userid: state.auth.userid
})

export default connect(mapStateToProps, mapDispatchToProps)(StatusBar);
