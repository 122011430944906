import * as actionTypes from "./types";
import { addToArray, updateObject } from "../tools";
import { deepCopy, isActionable, sortPhones } from "../../util/helper";
import { CLEAR_DATA } from "../common/types";
import { loadStateReducer } from "../../util/reusableReducers";
import { RELEASE_INACTIVE_PREFIX } from "../inactiveComponents/types";

const initialState = {
  getMyList: { loading: false, error: false },
  getMoreList: { loading: false, error: false },
  getActiveLimboList: { loading: false, error: false },
  numbers: {},
  sortedPhones: [],
  skipped: [],
  // listLoaded: false,
  assignedListLoaded: false,
  noNewNumbers: false,
  lastTextSent: new Date(0)
};

const base = (state, action) => {
  switch (action.type) {
    case actionTypes.GET_ACTIVE_LIMBO_LIST_GET_SUCCESS:
      return {
        ...state,
        getActiveLimboList: updateObject(state.getActiveLimboList, initialState.getActiveLimboList),
        selectedRecipientPhone: state.sortedPhones[0],
        assignedListLoaded: true,
      };
    case actionTypes.GET_ACTIVE_LIMBO_LIST_IS_LOADING:
      return {
        ...state,
        getActiveLimboList: updateObject(state.getActiveLimboList, {
          loading: true,
          error: false,
        }),
      }
    case actionTypes.GET_ACTIVE_LIMBO_LIST_HAS_ERROR:
      return {
        ...state,
        getActiveLimboList: updateObject(state.getActiveLimboList, {
          loading: false,
          error: true,
          errorMessage: action.message,
        }),
      }
    case actionTypes.GET_MY_LIST_IS_LOADING:
      return {
        ...state,
        getMyList: updateObject(state.getMyList, {
          loading: true,
          error: false,
        }),
        assignedListLoaded: false
      };
    case actionTypes.GET_MY_LIST_HAS_ERROR:
      return {
        ...state,
        getMyList: updateObject(state.getMyList, {
          loading: false,
          error: true,
          errorMessage: action.message,
        }),
        assignedListLoaded: false
      };
    // Both these actions also exist in the numbers reducer
    // that's where the actual list is assigned to state
    case actionTypes.GET_MY_LIST_POST_SUCCESS:
      return {
        ...state,
        getMyList: updateObject(state.getMyList, initialState.getMyList),
        selectedRecipientPhone: state.sortedPhones
          ? state.sortedPhones[0]
          : Object.keys(action.data.data)[0],
          assignedListLoaded: true
      };
    case actionTypes.GET_MORE_LIST_POST_SUCCESS:
      return {
        ...state,
        getMoreList: updateObject(state.getMoreList, initialState.getMoreList),
        // If no new numbers are loaded then set to true, if not we can reload
        // listLoaded: true,
        noNewNumbers: Object.keys(action.data.data).length === 0,
        selectedRecipientPhone: state.sortedPhones
          ? state.sortedPhones[0]
          : Object.keys(action.data.data)[0],
        actionableCount: updateActionableConversationCount(state)
      };
    case actionTypes.GET_MORE_LIST_IS_LOADING:
      return {
        ...state,
        getMoreList: updateObject(state.getMoreList, {
          loading: true,
          error: false,
        }),
        noNewNumbers: false
      };
    case actionTypes.GET_MORE_LIST_HAS_ERROR:
      return {
        ...state,
        getMoreList: updateObject(state.getMoreList, {
          loading: false,
          error: true,
          errorMessage: action.message,
        }),
        noNewNumbers: false
      };
    case actionTypes.UPDATE_ACTIONABLE_CONVERSATION_COUNT:
      return updateObject(state, {
        actionableCount: updateActionableConversationCount(state),
      });
    case actionTypes.UPDATE_SELECTED_RECIPIENT:
      return updateObject(state, { selectedRecipientPhone: action.phone });
    case RELEASE_INACTIVE_PREFIX +"_POST_SUCCESS":
      // if the currectly selected phone is in the list of inactive numbers
      // change that phone
      if (action.data.length > 0 && 
        state.numbers[state.selectedRecipientPhone] === undefined) {
        return updateObject(state, {
          selectedRecipientPhone: state.sortedPhones[0],
        });
      }
      return state;
    case actionTypes.REMOVE_CONVERSATION:
      // NOTE: This should automatically change the selected phone when one is removed
      // Just to explain, if the selected phone is the one removed then the if
      // is skipped over and the SELECT_NEXT_RECIPIENT code runs
      if (state.selectedRecipientPhone !== action.phone) {
        return state;
      }
    case actionTypes.SELECT_NEXT_RECIPIENT:
      return updateObject(state, {
        // We select index 1 if it exists to skip the first phone in the list because it's the one we just sent a message to
        // which updates the sortedPhones list when the API called has finished
        selectedRecipientPhone: state.sortedPhones[0],
      });
    case actionTypes.SET_CONVO_TERMINATING:
      return updateObject(state, {
        numbers: updateObject(state.numbers, {
          [action.phone]: updateObject(state.numbers[action.phone], {
            terminating: true,
          }),
        }),
      });
    case actionTypes.SET_CONVO_SKIPPED:
      // On skip select next phone
      if (action.skipped) {
        return updateObject(state, {
          selectedRecipientPhone: state.sortedPhones[0]
        });
      }
      return state;
    case actionTypes.UPDATE_LAST_TEXT_SENT:
      return updateObject(state, { lastTextSent: new Date() });
    case CLEAR_DATA:
      // When we log out, or when something else happens, we need to get rid of the data
      return initialState;
    default:
      return state;
  }
};

const updateActionableConversationCount = (state) => {
  let count = 0;
  if (state.numbers) {
    count = Object.values(state.numbers).filter(isActionable).length;
  }
  return count;
};

const numberListReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ACTIVE_LIMBO_LIST_GET_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case actionTypes.GET_MY_LIST_POST_SUCCESS:
    case actionTypes.GET_MORE_LIST_POST_SUCCESS:
      return {
        ...state,
        ...action.data.data,
      };
    case actionTypes.UPDATE_CONVERSATION_LOADING:
      return updateObject(state, {
          [action.phone]: updateObject(state[action.phone], { loading: action.isLoading, }),
        });
    case actionTypes.REMOVE_LAST_CONVERSATION_ITEM:
      return updateObject(state, {
          [action.phone]: updateObject(state[action.phone], { conversation: state[action.phone].conversation.slice(0, -1), loading: false,}),
      });
    case actionTypes.TOGGLE_RECIPIENT_REATTEMPT: {
      let recipient = state[action.phone];
      return updateObject(state, {
        [action.phone]: updateObject(recipient, {
          reattempt: action.reattempt,
        }),
      });
    }
    case actionTypes.REMOVE_CONVERSATION:
      let newNums = deepCopy(state);
      delete newNums[action.phone];
      return newNums;
    case actionTypes.ADD_ITEM_TO_CONVERSATION: {
      const recipient = state[action.phone];
      const updatedConvo = addToArray(recipient.conversation, action.blob);
      let updatedRecipient = {
        justsentscript: 0,
        conversation: updatedConvo,
        loading: action.loading,
      };
      if (action.currentscriptid !== undefined) {
        updatedRecipient.currentscriptid = action.currentscriptid;
      }

      return updateObject(state, {
        [action.phone]: updateObject(recipient, updatedRecipient),
      });
    }
    case actionTypes.UPDATE_CONVERSATION_NEXT_SCRIPT:
      return updateObject(state, {
        [action.phone]: updateObject(state[action.phone], {
          currentscriptid: action.nextScriptId,
        }),
      });
    case actionTypes.SET_CONVO_SKIPPED:
      return updateObject(state, {
        [action.phone]: updateObject(state[action.phone], {
          skipped: action.skipped,
        }),
      });
    case RELEASE_INACTIVE_PREFIX+"_POST_SUCCESS":
      let newNumsRel = deepCopy(state);
      // This is an array of phone numbers
      const nums = action.data; 
      nums.forEach(num => {
        delete newNumsRel[num];
      });
      return newNumsRel;
    case CLEAR_DATA:
      return {};
    default:
      return state;
  }
};

// TODO: Add reducers for: appendToConversation, endConversation, addRecipientToPanel
const appendToConversationReducer = loadStateReducer({
  [actionTypes.APPEND_TO_CONVERSATION_PREFIX+"_IS_LOADING"]: "loading",
  [actionTypes.APPEND_TO_CONVERSATION_PREFIX+"_HAS_ERROR"]: "error",
  [actionTypes.APPEND_TO_CONVERSATION_PREFIX+"_POST_SUCCESS"]: "success"
});


const final = (state = initialState, action) => {
  // NOTE: Order matters here!
  // We put the numbers in the numbers reducer to get that state done
  const phoneState = numberListReducer(state.numbers, action);

  // Then we sort that into the sorted list of phones
  const sorted = sortPhones(phoneState);
  // Finally, we create the final state using the base reducer
  return base({ ...state, numbers: phoneState, appendToConversation: appendToConversationReducer, sortedPhones: sorted }, action);
};

export default final;
