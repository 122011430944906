// async actions
export const GET_MORE_LIST_IS_LOADING = "GET_MORE_LIST_IS_LOADING";
export const GET_MORE_LIST_HAS_ERROR = "GET_MORE_LIST_HAS_ERROR";
export const GET_MORE_LIST_POST_SUCCESS = "GET_MORE_LIST_POST_SUCCESS";

export const GET_MY_LIST_IS_LOADING = "GET_MY_LIST_IS_LOADING";
export const GET_MY_LIST_HAS_ERROR = "GET_MY_LIST_HAS_ERROR";
export const GET_MY_LIST_POST_SUCCESS = "GET_MY_LIST_POST_SUCCESS";

export const GET_ACTIVE_LIMBO_LIST_PREFIX = "GET_ACTIVE_LIMBO_LIST";
export const GET_ACTIVE_LIMBO_LIST_IS_LOADING = "GET_ACTIVE_LIMBO_LIST_IS_LOADING";
export const GET_ACTIVE_LIMBO_LIST_HAS_ERROR = "GET_ACTIVE_LIMBO_LIST_HAS_ERROR";
export const GET_ACTIVE_LIMBO_LIST_GET_SUCCESS = "GET_ACTIVE_LIMBO_LIST_GET_SUCCESS";

export const END_CONVERSATION_PREFIX = "END_CONVERSATION";
export const ADD_RECIPIENT_TO_PANEL_PREFIX = "ADD_RECIPIENT_TO_PANEL";
export const APPEND_TO_CONVERSATION_PREFIX = "APPEND_TO_CONVERSATION";
export const ADD_TO_PERMANENT_OPTOUT_PREFIX = "ADD_TO_PERMANENT_OPTOUT";

// redux actions
export const SET_CONVO_TERMINATING = "SET_CONVO_TERMINATING";
export const REMOVE_CONVERSATION = "REMOVE_CONVERSATION";
export const UPDATE_ACTIONABLE_CONVERSATION_COUNT = "UPDATE_ACTIONABLE_CONVERSATION_COUNT";
export const UPDATE_SELECTED_RECIPIENT = "UPDATE_SELECTED_RECIPIENT";
export const SELECT_NEXT_RECIPIENT = "SELECT_NEXT_RECIPIENT";
export const ADD_ITEM_TO_CONVERSATION = "ADD_ITEM_TO_CONVERSATION";
export const TOGGLE_RECIPIENT_REATTEMPT = "TOGGLE_RECIPIENT_REATTEMPT";
export const UPDATE_LAST_TEXT_SENT = "UPDATE_LAST_TEXT_SENT";
export const TOGGLE_RECIPIENT_JUSTSENTSCRIPT = "TOGGLE_RECIPIENT_JUSTSENTSCRIPT";
export const UPDATE_CONVERSATION_NEXT_SCRIPT = "UPDATE_CONVERSATION_NEXT_SCRIPT";
export const SET_CONVO_SKIPPED = "SET_CONVO_SKIPPED";
export const UPDATE_CONVERSATION_LOADING = "UPDATE_CONVERSATION_ITEM";
export const REMOVE_LAST_CONVERSATION_ITEM = "REMOVE_LAST_CONVERSATION_ITEM";