import React, { useRef, useState, useCallback, useEffect, Children, cloneElement, isValidElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import SkipConversation from "../Skip/SkipConversation";
import TerminateNav from "./TerminateNav";
import PermanentOptOutNav from "./PermanentOptOutNav";

const DropdownMenuDivider = () => <hr className="navbar-divider" />;

export default function UserMenu({ phone, timedFinishConversation, addToPermanentOptOutAndTerminateConversation }) {
  const [isDropdown, setIsDropdown] = useState(true);

  useEffect(() => {
    document.getElementById("recipient-name").addEventListener("click", () => {
      setIsDropdown(!isDropdown);
    });
  }, [isDropdown]);

  return (
    <>
      <TerminateNav
        className='navbar-item'
        currentPhone={phone}
        timedFinishConversation={timedFinishConversation}
      />
      <SkipConversation phone={phone} />
      <PermanentOptOutNav
        currentPhone={phone}
        addToPermanentOptOutList={addToPermanentOptOutAndTerminateConversation}
        className="navbar-item"
      />
    </>
  );
}

