import React from "react";

import InactivityModalContainer from "../../components/Inactivity/Inactivity.container";

import SurveyToolContainer from "../SurveyTool/SurveyTool.container";

const Main = ({
  name,
  active,
  ...props
}) => {
  return (
    <div className="dcm panel vertical">
      <InactivityModalContainer />
      <SurveyToolContainer
        {...props}
      />
    </div>
  );
};

export default Main;
