import {
  TOGGLE_PROMPT_MODAL,
  UPDATE_CURRENT_SCRIPT_ITEM,
  SET_NEXT_QUESTION_PREVIEW,
  CLEAR_NEXT_QUESTION_PREVIEW,
} from "./types";

const initState = {
  promptModalVisible: false,
  transcript: null,
  phone: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_SCRIPT_ITEM:
      return {
        selectedScriptItem: "",
      };

    case TOGGLE_PROMPT_MODAL:
      return {
        promptModalVisible: action.visible,
      };
    default:
      return state;
  }
};
