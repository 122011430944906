import { fetchRequest } from "../../middleware/fetchMiddleware";
import { RELEASE_INACTIVE_PREFIX } from "./types";
/**
 * Post request to remove recipients from list that haven't had activity in, more than N minutes (20 by default)
 * @returns {Promise} The request promise
 */
export const releaseInactiveRecipients = () => (dispatch, getState) => {
  const state = getState();
  return dispatch(
    fetchRequest(
      RELEASE_INACTIVE_PREFIX,
      "POST",
      "/texter/releaseInactive",
      {
        campaignid: state.auth.campaignid,
      }
    )
  );
};
