import React, { Component } from "react";
import Modal from "react-modal";

Modal.setAppElement('#root');

class ModalBase extends Component {
  render() {
    const { toggleModal, theme = "light", open } = this.props;
    return (
      <Modal isOpen={open} className={`modal ${open ? "is-active" : ""}`} data={{ 'theme': theme }} overlayClassName='is-overlay' >
        <div className="modal-background" onClick={()=>toggleModal(false)} />
        <div className="modal-card">      
          {this.props.children}
        </div>
      </Modal>
    );
  }
}

export const ModalHeader = ({ children, }) => {
  return <header className="modal-card-head">
      <p className='modal-card-title'>
        {children}
      </p>
    </header>;
};

export const ModalBody = ({ children, }) => {
  return <section className="modal-card-body">{children}</section>;
};

export const ModalFooter = ({ children, toggleModal, hideCancel = false, className = "" }) => {
  return (
    <footer className={`modal-card-foot ${className}`}>
      {children}
      {!hideCancel && (
        <button className="button is-danger is-outlined" onClick={()=>toggleModal(false)}>
          Cancel
        </button>
      )}
    </footer>
  );
};

export default ModalBase;
