import React from "react";

export const Button = ({
  disabled,
  classSuffix = "",
  classPrefix = "",
  classOverride = null,
  additionalClasses = "",
  children,
  ...props
}) => {
  const classResults = classOverride? classOverride : `${classPrefix}button-one12${classSuffix}`;

  return (
    <button
      className={`${classResults} ${disabled? 'disabled ' : ''}${additionalClasses}`}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};
