import React from "react";
import ActionWithConfirmationNav from "./ActionWithConfirmationWrapper";

export default function TerminateNav({ currentPhone, timedFinishConversation, closeMenu, className = ''}) {
  return (
    <ActionWithConfirmationNav
      className={className}
      currentPhone={currentPhone}
      actionToConfirm={(phone) => timedFinishConversation(phone, "terminated", "agent terminate")}
      actionText="Terminate"
      confirmationModal={{
        headerText: "Terminate Conversation",
        bodyText: "Are you sure you want to terminate this conversation?",
        confirmText: "Terminate",
      }}
    />
  );
}
