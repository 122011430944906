import React, { Component, Fragment } from "react";
import ConfirmActionModal from "../modals/ConfirmActionModal";

class ActionWithConfirmationNav extends Component {
    state = {
        previousPhone: null,
        isConfirmationModalOpen: false,
        isLoading: false,
    };

    componentDidMount() {
        this.setState({
            previousPhone: this.props.currentPhone,
        });
    }

    componentDidUpdate() {
        if (this.state.previousPhone !== this.props.currentPhone) {
            this.setState({
                previousPhone: this.props.currentPhone,
                isConfirmationModalOpen: false,
            });
        }
    }

    performAction = () => {
        const { actionToConfirm, currentPhone } = this.props;
        this.setState({ isLoading: true },
            () =>
                actionToConfirm(currentPhone)
                    .finally(() => this.setState({ isLoading: false }))
        );
    }

    openConfirmationModal = () => {
        this.setState({
            isConfirmationModalOpen: true,
        });
    }

    render = () => {
        const {
            disabled = false,
            className = '',
            shouldOverrideClasses = false,
            actionText = "-",
            confirmationModal = {
                headerText: "Confirm Action",
                bodyText: "Are you sure you want to perform this action?",
                confirmText: "Confirm",
            },
        } = this.props;

        const { headerText, bodyText, confirmText } = confirmationModal;
        if (disabled)
            return (
                <a href="#" className={`has-text-danger-light has-background-light-grey disabled ${className}`} >
                    {actionText}
                </a>
            );

        return (
            <Fragment>
                <button className={shouldOverrideClasses ? className : `has-text-danger ${className}`} onClick={this.openConfirmationModal}>
                    {actionText}
                </button>
                <ConfirmActionModal
                    isOpen={this.state.isConfirmationModalOpen}
                    onClose={() => this.setState({ isConfirmationModalOpen: false })}
                    loading={this.state.isLoading}
                    onConfirm={this.performAction}
                    headerText={headerText}
                    bodyText={bodyText}
                    confirmText={confirmText}
                />
            </Fragment>
        );
    };
}

export default ActionWithConfirmationNav;