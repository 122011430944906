import React from 'react';
import ModalBase, {
    ModalHeader,
    ModalBody,
    ModalFooter,
} from './ModalBase';

function ConfirmActionModal({
    isOpen,
    onClose,
    onConfirm,
    headerText = "Confirm Action",
    bodyText = "Are you sure you want to perform this action?",
    confirmText = "Confirm",
    loading = false,
}) {
    return (
        <ModalBase open={isOpen} toggleModal={onClose}>
            <ModalHeader>{headerText}</ModalHeader>
            <ModalBody>
                <p>{bodyText}</p>
            </ModalBody>
            <ModalFooter toggleModal={onClose} hideCancel={loading} className="is-flex-direction-row-reverse is-justify-content-space-between">
                <button onClick={onConfirm} className={`button ${loading? "disabled" : ""}`}>
                    {loading ? <span className="loader" /> : confirmText}
                </button>
            </ModalFooter>
        </ModalBase>
    );
};

export default ConfirmActionModal;