import React from 'react';
import { replaceMiscVarsWithValues } from "../../util/helper";

import ModalBase, {
    ModalHeader,
    ModalBody,
    ModalFooter,
} from './ModalBase';

export default function PromptModal(props) {
    const { variables, open, togglePromptModal, prompts, fillScript } = props;

    function callbackAndClose(question) {
        togglePromptModal(false);
        fillScript(question);
    };

    return (
        <ModalBase open={open} toggleModal={togglePromptModal}>
            <ModalHeader>Please Select a Prompt</ModalHeader>
            <ModalBody>
                {prompts.map(({ question }, i) => {
                    // Replace any encoded {variables} in `question` with their actual value from `variables`
                    const filteredQuestion = replaceMiscVarsWithValues(question, variables);
                    return (
                        <div
                            className="dcm-sender-promptserver-row"
                            onClick={() => callbackAndClose(filteredQuestion)}
                            key={i}
                        >
                            {filteredQuestion}
                        </div>
                    );
                })}
            </ModalBody>
            <ModalFooter toggleModal={togglePromptModal} />
        </ModalBase>
    );
}