import React from "react";

export default function UserMenuItem({
    isActive = false,
    onClick,
    children,
    ...props
}) {
    function onClickCallback(e) {
        e.preventDefault();
        onClick();
    }

    let className = "navbar-item ";
    if (isActive) {
        className += "is-active ";
    }

    return (
        <button className={className} onClick={onClickCallback} {...props}>
            {children}
        </button>
    );
}