import React from "react";
import RecurringCallbackManager from "../../common/RecurringCallbackManager";
import { reduxConnectedComponent } from "../../util/reduxConnectedHOC";
import { releaseInactiveRecipients } from "../../redux/inactiveComponents/thunks";

// run every 10 minutes
const COMPLETE_TIMEOUT = 10.0;

/**
 * @callback ReleaseInactiveClbk
 * @returns {Promise} The request promise
 */

/**
 * @typedef Props
 * @type {object}
 * @property {ReleaseInactiveClbk} releaseInactiveRecipients Function to release inactive recipients
 */

/**
 * Component to release inactive records every 10 minutes
 * @param {Props} props
 * @returns jsx
 */
const InactiveRecordsCleanup = ({releaseInactiveRecipients}) => {
  return (
  <RecurringCallbackManager
    intervalTime={COMPLETE_TIMEOUT}
    intervalName="inactiveRecordsCleanup"
    task={releaseInactiveRecipients}
    executeTaskAtStart={false}
  />
)};

const InactiveRecordsCleanupContainer = reduxConnectedComponent(
  InactiveRecordsCleanup,
  undefined,
  { releaseInactiveRecipients }
);

export default InactiveRecordsCleanupContainer;
