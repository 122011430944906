import React from "react";
import { Button } from "../common/Button";
import AgentStatisticsView from "./UserStatistics.redux";
import ScriptErrorEvents from "./ScriptErrorEvents";
//----------------------------------------------------------------------------------------------------
// DCMTopBar

export const StatusBarr = ({
  logout,
  selectedCampaignName,
  campaignStatus,
  campaignid,
  userid,
  old,
}) => {
  return (
    old ? (
      <div className="dcm-topbar">
        <div className="dcm-topbar-brand">
          <img src="/s160_logo.png" alt="Survey160" />
        </div>
        <div className="dcm-topbar-events">
          <ScriptErrorEvents />
        </div>
        <div className="dcm-topbar-left dcm-topbar-campaign-info">
          survey: <span className="campaign">{selectedCampaignName} ({campaignid}) </span>
          {campaignStatus === "sandbox" && (
            <div>survey status: &nbsp;<span className="sandbox">Sandbox</span></div>
          )}
          {campaignStatus === "active" && (
            <div>survey status: &nbsp;<span className="active">Active</span></div>
          )}
          user: <span className="userid">{userid}</span>
        </div>
        <AgentStatisticsView />
        <div className="dcm-topbar-right">
          <Button onClick={logout} classOverride="button is-danger is-outlined">Log Out</Button>
        </div>
      </div>
    )
    :
    (<div className="status-bar is-flex is-flex-direction-column is-flex- p-3">
      <div className="dcm-topbar-brand">
        <img src="/s160_logo.png" alt="Survey160" />
      </div>
      <div className="events p-0">
        <ScriptErrorEvents />
      </div>
      <div className="dcm-topbar-left dcm-topbar-campaign-info">
        
        <p className="mb-2">
          <b>Survey:</b> <span className="campaign">{selectedCampaignName} ({campaignid}) </span>
        </p>

        {campaignStatus === "sandbox" && (
          <p className="mb-2">
            <b>Survey Status:</b> <span className="sandbox">Sandbox</span>
          </p>
        )}
        {campaignStatus === "active" && (
          <p className="mb-2">
            <b>survey status:</b> <span className="active">Active</span>
          </p>
        )}
        <p>
          <b>User:</b> <span className="userid">{userid}</span>
        </p>
      </div>
      <AgentStatisticsView />
      <div className="dcm-topbar-right">
            <Button onClick={logout} classOverride="button is-danger is-outlined">Log Out</Button>
      </div>
    </div>)
  );
};
