import React from "react";
import PropTypes from "prop-types";

export const MessageBox = ({
  justsentscript,
  composedText,
  senderChangedScript,
  nextScriptHolder,
  disabled=false
}) =>
  (justsentscript === 0 || !disabled) ? (
    <textarea
      className="dcm-sender-conversation-bottom-script pane message has-text-black"
      value={composedText}
      onChange={senderChangedScript}
      placeholder="Send a message..."
    />
  ) : (
    <div
      className="dcm-sender-conversation-bottom-script pane message disabled"
      ref={(node) => (nextScriptHolder = node)}
    >
      {composedText}
    </div>
  );

  MessageBox.prototypes = {
      justsentscript: PropTypes.number.isRequired,
      composedText: PropTypes.string.isRequired,
      senderChangedScript: PropTypes.func.isRequired,
      nextScriptHolder: PropTypes.object.isRequired
  };