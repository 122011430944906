import React, { Fragment } from "react";

import { BottomPanel } from "../../components/BottomPanel/BottomPanel";
import { BottomPanelActionButton } from "../../components/BottomPanel/BottomPanelActionButton";
import PromptModalContainer from "../../components/modals/PromptModal.container";

/**
 * Takes care of the business logic for the bottom panel screen
 */
export class BottomPanelScreen extends React.Component {
  state = {
    isInputDirty: false,
    composedText: "",
    forceDisabled: false,
    previousQuestionText: "",
    prompt: "",
  };

  /* 
  * This makes sure that the composedText is updated when the questionText prop changes (i.e. when the user selects a new answer)
  */
  static getDerivedStateFromProps(props, state) {
    console.group("BottomPanelScreen getDerivedStateFromProps");
    console.log("isIntro", props.isIntro);
    console.log("phoneChanged", props.phoneChanged);
    console.log("questionText", props.questionText);
    console.log("selectedAnswerId", props.selectedAnswerId);
    console.log("state.previousQuestionText", state.previousQuestionText);
    console.groupEnd();
    
    if (
      props.isIntro || // This also makes the input message unabled to be modified
      props.phoneChanged ||
      props.questionText !== state.previousQuestionText
    ) {
      const composedText = state.isInputDirty && props.selectedAnswerId === -1 ? state.composedText : props.questionText;
      return {
        composedText,
        previousQuestionText: props.questionText,
        forceDisabled: false,
      };
    }

    return null;
  }

  onTextChange = (text) => {
    console.log("BottomPanelScreen onTextChange", text);

    this.props.resetSelectedAnswer(true)
    this.setState({
      composedText: text,
      isInputDirty: text !== this.props.questionText,
    });
  }

  acceptAndSendAction = () => {
    const { acceptAnswerAction, selectedAnswerId } = this.props;
    const { composedText } = this.state;
    this.setState({
      forceDisabled: true,
    });
    acceptAnswerAction(composedText)
      .then(this.reset)
      .catch(() => console.error("Failed to accept answer"));
  }

  sendAction = (overrideMessage = null) => {
    console.log("BottomPanelScreen sendAction", this.state.composedText);
    this.setState({
      forceDisabled: true,
    })
    const message = overrideMessage || this.state.composedText;
    return this.props.sendAction(message).then(() => this.reset());
}

  reset = () => {
    this.setState({
      isInputDirty: false,
      forceDisabled: false,
      composedText: this.props.questionText,
    });
  }

  render() {
    const {
      inputPlaceholder = "",
      prompts,
      variables,
      questionText,
      isIntro,
      justsentscript,
      selectedAnswerId,
    } = this.props;

    const composedText = this.state.composedText === "" && selectedAnswerId !== -1 ? questionText : this.state.composedText;
    console.log("BottomPanelScreen", this.props, this.state);
    
    // const { questionText, isInputDirty } = this.state;
    const disabled = this.state.forceDisabled || questionText === "" && composedText === "" || justsentscript === 1;
    const isInputDirty = questionText !== composedText;
    const isSendOnly = isIntro || disabled || isInputDirty;
    const disableSend = disabled || composedText === "";
    const shouldAcceptOnly = composedText === null;

    // Only show the accept and send button if the input is not dirty (i.e. the user has not modified the text) and an answer is selected
    console.log("BottomPanelScreen isSendOnly", isSendOnly, "disabled", disabled, "isInputDirty", isInputDirty);

    return (
      <Fragment>
        <PromptModalContainer
          prompts={prompts}
          variables={variables}
          fillScript={this.onTextChange}
        />
        <BottomPanel
          leftComponentContent={
                <textarea
                  className="textarea messagebox"
                  placeholder={shouldAcceptOnly ? "" : inputPlaceholder}
                  value={composedText || ""}
                  onChange={(e) => this.onTextChange(e.target.value)}
                  disabled={disabled || shouldAcceptOnly}
                />
          }
          // action-button-wrapper displays the buttons in reverse order to the way they are defined
          rightComponentContent={
            <div className="action-button-wrapper">
              <BottomPanelActionButton
                disabled={disableSend}
                buttonType={isSendOnly ? "send" : "acc&send"}
                buttonAction={() => isSendOnly? this.sendAction() : this.acceptAndSendAction()}
                className="send-button medium is-outlined has-background-white"
              >
                {isSendOnly ? "Send" : shouldAcceptOnly ? "Accept" : "Accept & Send"}
              </BottomPanelActionButton>
              <BottomPanelActionButton
                buttonAction={this.reset}
                disabled={!isInputDirty}
                className="medium reset-button is-outlined m-0"
              >
                Reset
              </BottomPanelActionButton>
          </div>
        }
        />
      </Fragment>
    );
  }
}
